import React from 'react';
import logo from './images/logo.png';
import './App.css';
import {TrophyOutlined, DatabaseFilled, StarFilled, FireFilled} from '@ant-design/icons';
import { Carousel, Col, Row } from 'antd';
import banner1 from './images/haibao1.jpg';
import banner2 from './images/haibao2.jpg';
import banner3 from './images/haibao3.jpg';
import gameIcon1 from './images/1.png';
import gameIcon2 from './images/2.png';
import gameIcon3 from './images/3.png';
import gameIcon4 from './images/4.png';
import gameIcon5 from './images/5.png';
import gameIcon6 from './images/6.png';
import gameIcon7 from './images/7.png';
import gameIcon8 from './images/8.png';
import gameIcon9 from './images/9.png';
import gameIcon10 from './images/10.png';
import gameIcon11 from './images/11.png';
import gameIcon13 from './images/13.png';
import gameIcon14 from './images/14.png';
import gameIcon15 from './images/15.png';
import gameIcon16 from './images/16.png';
import wxTips from './images/wx.png';

function App() {
    const [selectedTag, setSelectedTag] = React.useState("All");

    const download = () => {
        const userAgent = navigator.userAgent;

        if (/iPad|iPhone|iPod/.test(userAgent)) {
            // 下载iOS安装包
            window.location.href = 'https://google.com/'; // 替换为iOS下载链接
        } else {
            window.location.href = 'https://download.ylwq.top/ylwq.apk';
        }
    };

    const isWechat = () => {
        const userAgent = navigator.userAgent;
        return /MicroMessenger/i.test(userAgent);
    }

  return (
    <div className="App">
        { isWechat() ? (
            <img src={wxTips} style={{width: "100%", height: "auto"}} />
        ) : (
            <>
                <div className="Header">
                    <img src={logo} className="App-logo"/>
                    <div className="login-btn-group">
                        <button className="login-btn" onClick={download}>
                            <TrophyOutlined className="login-btn-icon" />
                            <div className="login-btn-text">立即下載</div>
                        </button>
                    </div>
                </div>
                <div className='Main'>
                    <div className='banner-show'>
                        <Carousel autoplay effect='fade'>
                            <div>
                                <img className='carousel-img' src={banner1} onClick={download} />
                            </div>
                            <div>
                                <img className='carousel-img' src={banner2} onClick={download} />
                            </div>
                            <div>
                                <img className='carousel-img' src={banner3} onClick={download}/>
                            </div>
                        </Carousel>
                    </div>
                    <div className="tag-group">
                        <div className='tag-group-bg'>
                            <button 
                                className={selectedTag === "All" ? "tag-btn tag-btn-1 tag-btn-active" : "tag-btn tag-btn-1"}
                                onClick={() => setSelectedTag("All")}
                            >
                                <DatabaseFilled className='tag-icon' />
                                <div className='tag-text'>所有遊戲</div>
                            </button>
                            <button 
                                className={selectedTag === "Fishing" ? "tag-btn tag-btn-2 tag-btn-active" : "tag-btn tag-btn-2"}
                                onClick={() => setSelectedTag("Fishing")}
                            >
                                <StarFilled className='tag-icon' />
                                <div className='tag-text'>捕魚遊戲</div>
                            </button>
                            <button 
                                className={selectedTag === "Poker" ? "tag-btn tag-btn-3 tag-btn-active" : "tag-btn tag-btn-3"}
                                onClick={() => setSelectedTag("Poker")}
                            >
                                <FireFilled className='tag-icon' />
                                <div className='tag-text'>撲克遊戲</div>
                            </button>
                        </div>
                    </div>
                    <div className='game-icon-group'>
                        <Row gutter={[16, 16]}>
                            {
                                selectedTag === "All" || selectedTag === "Fishing" ? 
                                <Col span={6}>
                                    <img src={gameIcon1} className='game-icon' onClick={download}/>
                                </Col> : null
                            }
                            {
                                selectedTag === "All" || selectedTag === "Fishing" ? 
                                <Col span={6}>
                                    <img src={gameIcon2} className='game-icon' onClick={download}/>
                                </Col> : null
                            }
                            {
                                selectedTag === "All" || selectedTag === "Fishing" ? 
                                <Col span={6}>
                                    <img src={gameIcon3} className='game-icon' onClick={download}/>
                                </Col> : null
                            }
                            {
                                selectedTag === "All" || selectedTag === "Fishing" ? 
                                <Col span={6}>
                                    <img src={gameIcon4} className='game-icon' onClick={download}/>
                                </Col> : null
                            }
                            {
                                selectedTag === "All" || selectedTag === "Fishing" ? 
                                <Col span={6}>
                                    <img src={gameIcon5} className='game-icon' onClick={download}/>
                                </Col> : null
                            }
                            {
                                selectedTag === "All" || selectedTag === "Fishing" ? 
                                <Col span={6}>
                                    <img src={gameIcon6} className='game-icon' onClick={download}/>
                                </Col> : null
                            }
                            {
                                selectedTag === "All" || selectedTag === "Poker" ? 
                                <Col span={6}>
                                    <img src={gameIcon7} className='game-icon' onClick={download}/>
                                </Col> : null
                            }
                            {
                                selectedTag === "All" || selectedTag === "Poker" ? 
                                <Col span={6}>
                                    <img src={gameIcon8} className='game-icon' onClick={download}/>
                                </Col> : null
                            }
                            {
                                selectedTag === "All" ? 
                                <Col span={6}>
                                    <img src={gameIcon9} className='game-icon' onClick={download}/>
                                </Col> : null
                            }
                            {
                                selectedTag === "All" ? 
                                <Col span={6}>
                                    <img src={gameIcon10} className='game-icon' onClick={download}/>
                                </Col> : null
                            }
                            {
                                selectedTag === "All" ? 
                                <Col span={6}>
                                    <img src={gameIcon11} className='game-icon' onClick={download}/>
                                </Col> : null
                            }
                            {
                                selectedTag === "All" || selectedTag === "Poker" ? 
                                <Col span={6}>
                                    <img src={gameIcon13} className='game-icon' onClick={download}/>
                                </Col> : null
                            }
                            {
                                selectedTag === "All" || selectedTag === "Poker" ? 
                                <Col span={6}>
                                    <img src={gameIcon14} className='game-icon' onClick={download}/>
                                </Col> : null
                            }
                            {
                                selectedTag === "All" || selectedTag === "Poker" ? 
                                <Col span={6}>
                                    <img src={gameIcon15} className='game-icon' onClick={download}/>
                                </Col> : null
                            }
                            {
                                selectedTag === "All" || selectedTag === "Poker" ? 
                                <Col span={6}>
                                    <img src={gameIcon16} className='game-icon' onClick={download}/>
                                </Col> : null
                            }
                        </Row>
                    </div>
                </div>
            </>
        )}
    </div>
  );
}

export default App;
